<template>
  <!-- 票据管理 -->
  <div>
    <!-- 搜索框开始 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      reset
      :form-item-arr="searchFormItemArr"
    >
      <template>
        <el-form-item
          label="所属企业"
          prop="cmpCode"
        >
          <el-select
            v-model="formInline.cmpCode"
            class="cmpNameSearch"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键字搜索"
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in cmpNameArr"
              :key="item.cmpCode"
              :label="item.cmpName"
              :value="item.cmpCode"
            />
          </el-select>
        </el-form-item>
      </template>
    </FormSearch>
    <!-- 搜索框结束 -->
    <!-- 列表开始 -->
    <div class="page-container-table">
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
      />
      <!-- 分页组件 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 列表结束 -->
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch.vue'
import Pagination from '@/components/Pagination2.vue'
import Table from '@/components/Table.vue'
import { manageListPage } from '@/api/piaoju'
import { getCmpBaseinfoList } from '@/api/memberManagement'

export default {
  components: { FormSearch, Pagination, Table },
  data() {
    return {
      cmpNameArr: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      searchFormItemArr: [
        { type: 'input', label: '票据号码', value: 'billNo' },
        // {
        //   type: 'select',
        //   label: '票据类型',
        //   value: 'billType',
        //   pLabel: 'dictName',
        //   pValue: 'dictId',
        //   child: this.$store.getters.getDictionaryItem('BILL_TYPE')
        // },
        {
          type: 'input',
          label: '出票人',
          value: 'drawer'
        },
        {
          type: 'input',
          label: '承兑人',
          value: 'acceptor'
        },
        {
          type: 'input',
          label: '收款人',
          value: 'payee'
        },
        {
          type: 'picker',
          label: '出票日期',
          value: 'drawerDateArr',
          startTimer: 'drawerStartDate',
          endTimer: 'drawerEndDate'
        },
        {
          type: 'picker',
          label: '到期日期',
          value: 'expireDateArr',
          startTimer: 'expireStartDate',
          endTimer: 'expireEndDate'
        },
        {
          type: 'select',
          label: '状态',
          value: 'billManageStatus',
          pLabel: 'dictName',
          pValue: 'dictId',
          child: this.$store.getters.getDictionaryItem('BILL_MANAGE_STATUS')
        }
      ],
      itemData: [
        { label: '票据号码', prop: 'billNo', width: 200 },
        { label: '票据类型', prop: 'billType', width: 160, child: this.$store.getters.getDictionaryItem('BILL_TYPE') },
        { label: '出票人', prop: 'drawer', width: 200 },
        { label: '承兑人', prop: 'acceptor', width: 200 },
        { label: '收款人', prop: 'payee', width: 200 },
        { label: '出票日期', prop: 'drawerDate', width: 160 },
        { label: '到期日期', prop: 'expireDate', width: 160 },
        { label: '票面金额', prop: 'faceAmount', width: 160, type: 'money' },
        { label: '所属企业', prop: 'cmpName', width: 160 },
        { label: '创建时间', prop: 'createTime', width: 160 },
        {
          label: '状态',
          prop: 'billManageStatus',
          width: 160,
          child: this.$store.getters.getDictionaryItem('BILL_MANAGE_STATUS')
        }
      ],
      listData: [],
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.viewDetails, withParameters: '/piaoju/detailBill' }
      ],
      total: 0
    }
  },
  mounted() {
    this.remoteMethod('')
  },
  methods: {
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      } else if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      }
      manageListPage({ ...this.formInline }, (res) => {
        if (res.data.records) {
          this.listData = [...res.data.records]
          this.total = res.data.total
          return
        }
        this.listData = []
        this.total = 0
      })
    },
    // 查看详情
    viewDetails({ id }, withParameters) {
      this.$router.push({
        path: withParameters,
        query: { id }
      })
    },
    // 联动搜搜货主列表
    remoteMethod(value) {
      getCmpBaseinfoList(value || '', res => {
        this.cmpNameArr = res.data
      })
    }
  }
}
</script>
